/** 내강의실 정렬 값을 저장하는 키. [등록순 | 최근 본 순] */
export const MYPAGE_STUDYROOM_ORDER_COLUMN_KEY = 'wb:mypage:studyroom:order-column';

/** PWA설치 모달 노출 횟수 */
export const PWA_GUIDE_MODAL_SHOWING_COUNT_KEY = 'wb:pwa:showing-count';

/** 커뮤니티 가이드 팔로우 눌러서 로그인/회원가입 진행 여부 */
export const LOGIN_VIA_COMMUNITY_GUIDE_KEY = 'wb:community:login-via-community-guide';

/** 커뮤니티 검색 스크롤 위치 */
export const COMMUNITY_SEARCH_SAVED_SCROLL_POSITION_KEY = 'wb:community:search:savedScrollPosition';

/** 커뮤니티 게시판 스크롤 위치 */
export const COMMUNITY_BOARD_SAVED_SCROLL_POSITION_KEY = 'wb:community:board:savedScrollPosition';

/** 월부커 상세페이지 > 댓글 보기 (등록순, 최신순) */
export const COMMUNITY_COMMENTS_SORT_KEY = 'wb:community:posts:comments:sort';
